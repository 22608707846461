.influence-body{
    padding-top: 120px;
}
#in-arr1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;

}

#in-arr2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    /* or 151% */

    text-align: center;
    letter-spacing: -1px;

    background: linear-gradient(180deg, #00888F 0%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    /* width: 65%; */
    margin: 2rem auto;
    mix-blend-mode: normal;
}

#in-arr3 {
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    margin: auto;
    align-items: center;
    justify-content: space-evenly;
    margin: 5rem auto;
}

.in-flex-div {
    width: 20vw;
    display: flex;
    margin: 3rem 5vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.in-flex-div img {
    width: 80px;
}

.flex-div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    width: 80%;
    margin: auto;
    color: #000000;
    margin-top: 20px;
}


@media only screen and (max-width:1100px) {
    #in-arr1 {
        margin: 4rem 0px;
        text-align: center;
    }

    #in-arr2 {
        font-size: 25px;
        line-height: 40px;
        margin-left: 15px;
        margin-right: 15px;

    }

    .in-flex-div {
        width: 34vw;
        margin: 5vw;
    }

    .in-flex-div img {
        width: 60px;
    }

    .flex-div {
        font-size: 12px;
        line-height: 20px;
        width: 100%;
    }
}