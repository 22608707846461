.footer-main{
   background-image: linear-gradient(to right, #00888F , #560351);
   border-radius: 20px;
   margin: 15px;
   padding-top: 80px;
}
.footer-container{
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;

}
.quick-links{
   font-weight: bold;
   color: rgba(255, 255, 255, 0.604);
   font-size: 1rem;
}
.quick-links a{
   text-decoration: none;
   color: rgba(255, 255, 255, 0.613);
}
.quicklinks-container{
   padding-bottom: 25px;
   border-bottom: 3px solid rgba(255, 255, 255, 0.604);
   margin-bottom: 25px;
   padding-right: 100px;
}
#clients1{
   margin-left: 150px;
}
#clients2{
   margin-left: 110px;
}
#clients3{
   margin-left: 138px;
}
.get-in-touch{
   padding-bottom: 25px;
   border-bottom: 3px solid rgba(255, 255, 255, 0.604);
   margin-bottom: 25px;
   padding-right: 300px;
   margin-left: 150px;
   color: rgba(255, 255, 255, 0.593);
   font-weight: bold;
}
.footer-right{
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
}
.get-in-touch-p{
   color: white;
   margin-left: 150px;
   line-height: 40px;
}
.footer-bottom ul{
   color: rgba(255, 255, 255, 0.613);
   display: flex;
   list-style: none;
   margin-left: 20.5vw;
}
.footer-bottom ul li{
   margin-left: 50px;
   transition-duration: 0.5s;
}
.footer-bottom ul li:hover{
   transform: scale(1.05);
   cursor: pointer;
   color: white;
}
.link-footer{
   cursor: pointer;
   transition-duration: 0.5s;
}
.link-footer:hover{
   transform: scale(1.35) !important;
   color: white;
}
.footer-bottom{
   padding-bottom: 50px;
  }
  .footer-left-main-container{
   display: flex;
  }
@media screen and (min-width: 1600px) {
  .footer-bottom ul{
   margin-left:26vw;
  }
  .footer-bottom{
   padding-bottom: 50px;
  }
 }

 @media only screen and (max-width:1300px){
   .footer-bottom ul {
       color: rgba(255, 255, 255, 0.613);
       display: flex;
       list-style: none;
       justify-content: center;
       margin-left: -60px;
   }
}
@media only screen and (max-width:1250px){
   .footer-right {
       display: flex;
       justify-content: space-evenly;
       flex-wrap: wrap;
       padding-top: 60px;
   }
}
@media only screen and (max-width:1000px){
   .get-in-touch {
      padding-bottom: 25px;
      border-bottom: 3px solid rgba(255, 255, 255, 0.604);
      margin-bottom: 25px;
      padding-right: 0px;
      margin-left: 150px;
      color: rgba(255, 255, 255, 0.593);
      font-weight: bold;
      padding-top: 70px;
  }
  .footer-bottom {
   padding-bottom: 50px;
   padding-right: 20px;
   line-height: 37px;
}

.footer-bottom ul {
   color: rgba(255, 255, 255, 0.613);
   display: flex;
   list-style: none;
   justify-content: center;
   flex-direction: column;
   justify-content: center;
   margin-left: -60px;
   text-align: center;
}
#twit{
   display: none;
}
#lin{
   display: none;
}
.quicklinks-container {
   padding-bottom: 25px;
   border-bottom: 3px solid rgba(255, 255, 255, 0.604);
   margin-bottom: 25px;
   padding-right: 0px;
   display: flex;
}
.footer-left-main-container{
   width: 100vw;
   display: block;
}
.quick-links{
   width: 100vw;
   max-width: 300px;
   display: block;
   margin: auto;
   text-align: center;
}
.get-in-touch{
   margin-left: 0px;
}
.get-in-touch-p {
   color: white;
   margin-left: 0px;
}
.get-main-head {
   width: 90vw;
   display: block;
   margin: auto;
   text-align: center;
   max-width: 300px;
   margin-bottom: 25px;
}
}
.footer-bottom a{
   color: #ffffff9c;
   text-decoration: none;
}
.footer-bottom a:hover{
   color: #ffffff;
  
}
.Footer-call-text{
   font-weight: 600;
}
.get-main-head span a{
   color:#fff;
   text-decoration: none;
}