.contact-container{
   padding: 3px;
   background: linear-gradient(to right, #00888F, #580053);
   border-radius: 10px;
   margin-left: 10vw;
   margin-bottom: 100px;
}

.contact-container{
   width: 45vw;
   max-width: 850px;
   min-width: 300px;
}
.contat-form-container{
   width: 100%;
   border-radius: 10px;
}
.contat-form-container input{
   width: 95%;
   background-color: #F0F0F0;
   border: 1px solid #e1e1e1;
   padding: 15px;
   border-radius: none;
   margin-bottom: 20px;
   outline: #00888F;
}

.form-contact-child{
   padding-top:50px ;
   padding-bottom: 50px;
}
.form-contact-child h4{
   font-size: 2rem;
   text-align: center;
   margin-bottom: 80px;
}
.form-contact-child{
   width: 80%;
   display: block;
   margin: auto;
}
.contat-form-container p{
   font-family: Inter;
   font-size: 1.2rem;
   font-weight: 600;
   line-height: 24px;
   letter-spacing: -0.5px;
   text-align: left;
   opacity: 0.4;

}
#hearAboutUs{
   height: 50px;
   text-overflow: wrap;
}
.contat-form-container select{
   width: 99%;
   background-color: #F0F0F0;
   border: 1px solid #e1e1e1;
   padding: 15px;
   border-radius: none;
}
.form-container-contact-parent{
   position: relative;
   bottom: 50px;
   overflow: hidden;
}
.contact-body-img img{
   position: absolute;
   z-index: -1;
   width: 70vw;
   right:0;
   top: 300px;
}
.contact-submit-form{
   background: linear-gradient(to right, #00888F, #580053);
   border-radius: 10px;
   border: none;
   color: white;
   padding: 10px 40px;
   display: block;
   font-size: 1.3rem;
   margin-top: 50px;
   margin-bottom: 20px;
   margin-left: auto;
   margin-right: auto;
}
.contact-container h6{
   font-size: 0.85rem;
   opacity: 0.4;
   text-align: center;
}
.contact-container a{
   color: black;
}

.contact-form-section{display:flex;}
.contact-form-img-sec{width:50%;}
.contact-form-img-sec img{width:100%; border-radius: 20px;}
.contact-form-sec{width:50%;}
.second-img{width:80%; margin:auto;}
.form-contact-child {
   border: 3px solid #14728a;
   padding: 40px;
   border-radius: 20px;
}
.form-contact-child h6{
   text-align: center;
}
.form-contact-child h4 {
   margin: 0px 0px 35px 0px!important;
}

#back-light1 {
   display: flex;
   background: transparent;
   position: absolute;
   top: 150px;
   height: 76%;
   z-index: -1;
}

@media screen and (max-width: 1000px) {
   .contact-container{
      width: 90vw;
      max-width: 850px;
      min-width: auto;
   }
   .form-container-contact-parent{
      position: relative;
      bottom: 0px;
      margin-top: -160%;
   }
   .contact-container{
      margin:auto;
      position: relative;
      bottom: 250px;
   }
   .contact-body-img{
      display: none;
   }
   .contat-form-container select {
      width: 100%;
   }
   #arr5-2-1 {
      margin-bottom: 16px;
  }
  .contat-form-container input {
   width: 90%;
  }
 }
 @media (min-width: 320px) and (max-width: 480px) {
  
   .contact-form-section{display:block;padding: 15px;}
   .second-img{width:100%;display: none;}
   .contact-form-img-sec {width: 100%;}
   .headText-light1 {margin-left: 1rem; width: 100%; font-size: 45px; line-height: 60px; }
   .head-head1 { margin-top: 0rem; margin-left: 1rem; width: 100%; }
   .contact-form-img-sec img{width:100%; margin-top: 3.5em; border-top-right-radius: 20px; border-bottom-right-radius: 20px;}
   .contact-form-sec{width:100%;}
   .form-contact-child {width: auto;}
   .contat-form-container .input {width: 90%; }
   .header-light .headText-light {font-size: 26px; margin-top: .1rem;line-height: 50px;}
   .contat-form-container select {width: 102%;}
   .contact-meeting{margin-left: 3rem; margin-top: 3em; margin-bottom: 3em;}
 }