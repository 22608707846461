.contat-form-container .input{
    width: 95%;
    background-color: #F0F0F0;
    border: 1px solid #e1e1e1;
    padding: 5px 15px;
    border-radius: none;
    margin-bottom: 20px;
    outline: #00888F;
 }
 .contat-form-container .form-control{
    background: none!important;
    border: 1px solid #cacaca00!important;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 95%;
    outline: none;
 }
 
.headText-light1 {
   font-family: 'Inter';
   font-style: normal;
   width: 70%;
   font-weight: 300;
   font-size: 150px;
   margin-left: 6rem;
   margin-top: 0rem;
   margin-bottom: 0.5em;
   line-height: 174px;
   letter-spacing: -1px;
   color: transparent;
   mix-blend-mode: normal;
   max-width: 926px;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 300;
   font-size: 45px;
   line-height: 60px;
   /* or 119% */

   letter-spacing: -1px;

   background: linear-gradient(180deg, #00888F 0%, #770370 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;

   mix-blend-mode: normal;
}

.head-head1 {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 600;
   font-size: 30px;
   margin-top: 0rem;
   margin-left: 6rem;
   /* or 318% */
   width: 90%;
   letter-spacing: -1px;

   background: linear-gradient(180deg, #00888F 0%, #770370 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;

   mix-blend-mode: normal;
}
.contact-meeting{
   margin-left: 6rem;
   margin-top: 3em;
   margin-bottom: 3em;
}
.contact-meeting a{
   background: #30044c;
   color: #fff;
   text-decoration: none;
   padding: 1rem 3rem;
   font-size: 1.2em;
   font-weight: 600;
   border-radius: 4px;
}