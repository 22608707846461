@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #155b83;
}

.slick-prev {
    left: 25px;
    z-index: 2;
}

.slick-next {
    right: 25px;
}

.slick-prev:before, .slick-next:before {
    font-size: 25px;
    color: #155b839d;
}

