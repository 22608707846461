@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.header-light {
    /* background-image: url('../assets/img/home/Hero.png'); */

    /* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25); */
    min-height: 961px;
    padding-top: 0.5px;
    position: relative;
    /* background-size: cover; */
    overflow: hidden;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
    margin: auto;
    margin-top: 50px;
}

#header-ham-menu img {
    height: 35px;
    position: relative;
    bottom: 12px;
    display: none;
    
}
#menu1{
    filter: invert(1);
}
#get-started1{
    display: block;
    margin: auto;
    margin-top: 50px;
}

.header-light nav ul {
    display: flex;
    width: 70%;
    align-items: center;
    margin: auto;
    justify-content: space-evenly;
}

.header-light nav ul li a {
    color: transparent;
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    
    line-height: 18px;
    /* color:white; */
}
#mob-menu-container-light a{
    text-decoration: none;
}


.header-light nav ul li {
    list-style: none;
    transition-duration: 0.5s;
    /* margin: 1rem; */
}
#get-started-2{
    background: linear-gradient(98.29deg, #0098BB 13.95%, #340031 83.83%);
    color: white;
    cursor: pointer;
}
#get-started-2 span{
    color: white;
}
#get-started-light {
    transition-duration: 0.5s;
    background: linear-gradient(98.29deg, #0098BB 13.95%, #340031 83.83%);
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-left: 10rem;
    margin-top: 3rem;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

#get-started-light span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

#get-started-light:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.header-light nav ul li:hover {
    transform: scale(1.05);


}

.header-light .headText-light {
    font-family: 'Inter';
    font-style: normal;
    width: 60%;
    font-weight: 300;
    font-size: 150px;
    margin-left: 10rem;
    margin-top: 0rem;
    line-height: 174px;
    letter-spacing: -1px;
    color: transparent;
    mix-blend-mode: normal;
    max-width: 926px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 80px;
    line-height: 96px;
    /* or 119% */

    letter-spacing: -1px;

    background: linear-gradient(180deg, #00888F 0%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    mix-blend-mode: normal;
}

.header-light .headText-light span {
    font-weight: bold;
}

.header-light .subText-light {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    /* line-height: 174px; */
    margin-top: 2rem;
    /* or 694% */
    margin-left: 10rem;
    display: flex;
    align-items: center;
    letter-spacing: -1px;

    /* color: #FFFFFF; */

    mix-blend-mode: normal;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    /* or 162% */

    letter-spacing: -1px;

    color: #000000;
    width: 55%;
    mix-blend-mode: normal;
}

.header-light button {
    /* margin-left: 10rem;
    margin-top: 2rem; */
    background: #FFFFFF;
    border-radius: 5px;
    width: 155px;
    border: none;
    height: 42px;
    font-size: 10px;
    box-sizing: border-box;
}

.header-light button span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    font-size: 12px;
    color: black;
}

#back-light {
    display: flex;
    background: transparent;
    position: absolute;
    top: 150px;
    height: 76%;
    z-index: -1;
}

.head-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 96px;
    margin-top: 6rem;
    margin-left: 10rem;
    /* or 318% */
    width: 90%;
    letter-spacing: -1px;

    background: linear-gradient(180deg, #00888F 0%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    mix-blend-mode: normal;
}


#back1 {
    animation: move1 15s linear infinite;
    opacity: 1;
    width: 101vw;
    filter: grayscale(0);
}

#back2 {
    animation: move2 15s linear infinite;
    opacity: 1;
    filter: grayscale(0);
    width: 101vw;
}





@keyframes move1 {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes move2 {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    50.01% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(-100%);
    }

}



#wave {
    position: absolute;
    height: 200vw;
    /* width: 50px; */
    background-color: rgba(255, 255, 255, 0.4);
    top: 0px;
    left: 0px;
    opacity: 0.25;
    border-radius: 20px;
    rotate: 36deg;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.3),
        0 0 25px 0px rgba(255, 255, 255, 0.3),
        0 0 50px 0px rgba(255, 255, 255, 0.3),
        0 0 100px 0px rgba(255, 255, 255, 0.3),
        0 0 200px 0px rgba(255, 255, 255, 0.1),
        0 0 300px 0px rgba(255, 255, 255, 0.1);
    animation: waveLight 10s linear infinite;
}

@keyframes waveLight {
    0% {
        top: -100vw;
        left: 0px;
        rotate: 36deg;
    }

    25% {
        rotate: 0deg;
    }

    50% {
        rotate: 36deg;
    }

    100% {
        rotate: 0deg;
        left: 100vw;
    }

}


#mob-menu-container-light {
    width: 0vw;
    height: 100vh;
    background: linear-gradient(102.57deg, #00888F 0%, #670261 77.59%), linear-gradient(180deg, #232323 0%, #101010 100%);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transition-duration: 0.5s;
}

.mob-cont-top-light {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-top: 15px;
}

.mob-cont-top-light img {
    height: 60px;
}

.X {
    color: white;
    font-size: 1.7rem;
}

.list-mob-cont-light ul {
    color: transparent;
    display: flex;
    flex-direction: column;
    line-height: 30px;
    text-align: center;
    font-size: 1.2rem;
    padding-left: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    background: linear-gradient(90.02deg, #00888F 0.14%, #770370 91.32%);
    -webkit-background-clip: text;
    
    background-clip: text;
    text-fill-color: transparent;
}

@media screen and (min-width: 1100px) {
    .list-mob-cont-light ul{
        -webkit-text-fill-color: transparent;
    }
    
    .header-light nav ul li a {
        color: transparent;
        text-decoration: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        background: linear-gradient(90.02deg, #00888F 0.14%, #770370 91.32%);
        -webkit-background-clip: text;
        
        background-clip: text;
        text-fill-color: transparent;
        line-height: 18px;
        /* color:white; */
    }
  }

.list-mob-cont-light ul li {
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 40px;
}

.list-mob-cont-light li a {
    display: block !important;
}



@media only screen and (max-width:1350px) {
    .head-head {
        margin-top: 2rem;
        font-size: 25px;
    }
    #get-started-2{
        display: none;
    }
    .header-light .headText-light {
        font-size: 60px;
        line-height: 80px;
        margin-top: 0rem;
    }

    .header-light {
        min-height: 750px;
    }

    .header-light .subText-light {
        font-size: 19px;
    }

}


@media screen and (max-width: 1000px) {

    #header-ham-menu img {
        display: block;
    }

    .list-mob-cont-light ul {
        color: white;
    }

    #back1 {
        animation: move1 15s linear infinite;
        opacity: 0.5;
        height: 100vh;
        width: 300vw;
        top: 0px;
        position: relative;
        filter: grayscale(1);
    }

    #back2 {
        opacity: 0.5;
        height: 100vh;
        width: 300vw;
        top: 0px;
        position: relative;
    }

    .header-light nav ul li a {
        color: white;
    }


}











@media only screen and (max-width:1000px) {

    body,
    html {
        overflow-x: hidden;
    }

    .header-light .headText-light {
        width: 85%;
        font-size: 45px;
        margin-left: 2rem;
        margin-top: 1rem;
        line-height: 74px;
    }

    .header-light .subText-light {
        margin-left: 2rem;
        width: 250px;
    }

    .logo img {
        height: 60px;
    }

    .header-light {
        min-height: 802px;
    }


    #back-light {
        top: 317px;
        height: 170px;
        z-index: -1;
    }





    .head-small-light {
        width: 80%;
        font-size: 20px;
        line-height: 25px;
    }

    .sub-text-light {
        font-size: 15px;
        line-height: 25px;
    }

    .subHeading-light {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 34px;
        width: 88%;
        text-align: justify;
        letter-spacing: -0.05em;
        color: #FFFFFF;
    }

    .heading-light {
        font-size: 20px;
        line-height: 50px;
    }

    .header-light nav ul li a {
        display: none;
        color: white !important;
    width: 100vw;
    padding-bottom: 15px;
    }

    #get-started-light {
        transition-duration: 0.5s;
        display: none;
    }

    .head-head {
        width: 81%;
        margin: auto;
    }


}