#b-arr11 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 120.69%;
    /* or 72px */

    letter-spacing: -1px;

    color: #000000;
}


#barr121 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 146.19%;
    /* or 58px */
    width: 26%;
    text-align: left;
    letter-spacing: -1px;

    color: #FFFFFF;
}


#b-arr12 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 90%;
    margin-left: 10%;
    background: linear-gradient(102.57deg, #00888F 0%, #670261 77.59%), linear-gradient(180deg, #232323 0%, #101010 100%);
    /* width: 1366px; */
    justify-content: space-between;
    padding: 5rem 5rem;
    box-sizing: border-box;
    min-height: 525px;

}

#barr12-img {
    position: absolute;
    top: -60%;
    right: 0;
}

#b-arr1 {
    margin-top: 20rem;
    margin-bottom: 10rem;
}

#b-arr11 {
    width: 25%;
    margin-right: auto;
    margin-left: 15%;
    margin-bottom: 2rem;
}

#b-new {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    /* identical to box height, or 142% */
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    margin-left: 15%;
    color: #000000;
    width: 23%;
    mix-blend-mode: normal;
}

.b-arr2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5rem;
    box-sizing: border-box;
}

.side-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 120.69%;
    /* or 72px */
    width: 85%;
    letter-spacing: -1px;
    margin: 2rem 0px;
    background: linear-gradient(180deg, #00888F 0%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.side-2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 159.69%;
    /* or 32px */
    width: 100%;
    text-align: justify;
    padding: 2rem 0px;
    padding-bottom: 5rem;
    color: #000000;
    border-bottom: 1px solid #B4B4B4;

}

.side {
    width: 34%;
}

#one {
    flex-direction: row-reverse;
}

#width-91 {
    width: 91%;
}

#width-76 {
    width: 76%;
}

#last-btn2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: block;
    margin: 3rem auto;
    text-align: center;
    text-transform: uppercase;
    height: 3rem;
    border: none;
    width: 10rem;
    color: #FFFFFF;
    background: linear-gradient(98.29deg, #0098BB 13.95%, #340031 83.83%);
    border-radius: 5px;
    margin-bottom: 10rem;
}

#last-btn1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: block;
    margin: 3rem 0;
    text-align: center;
    text-transform: uppercase;
    height: 3rem;
    border: none;
    width: 10rem;
    color: #FFFFFF;
    background: linear-gradient(98.29deg, #0098BB 13.95%, #340031 83.83%);
    border-radius: 5px;
    margin-bottom: 10rem;
}

#b-arr3 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 5rem;
    margin: 5rem 0px;
}

#text-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 129.52%;
    /* or 52px */


    background: linear-gradient(180deg, #00888F 0%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

#text-2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 129.52%;
    /* or 52px */
    margin: 2rem 0px;

    background: linear-gradient(180deg, #00888F 0%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

#arr3-text {
    width: 33%;
}

#attr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 64px;
    /* or 212% */
    width: 11%;
    margin: auto;
    text-align: center;
    letter-spacing: -0.055em;
    text-transform: uppercase;

    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

@media only screen and (max-width:1600px){
    #attr{
        width: 20%;
    }
}

@media only screen and (max-width:1350px){
    #barr12-img {
        top: -56.5%;
        width: 55%;
    }
    #barr121{
        font-size: 25px;
    }
    #b-arr12 {
        min-height: 450px;
    }
    #b-arr11{
        font-size: 40px;
    }
    #b-new {
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .b-arr2 img{
        width: 40%;
    }
    .side-1{
        width: 100%;
        font-size: 42px;
    }
    .side-2{
        font-size: 17px;
    }
    .b-arr2{
        padding: 2rem;
    }
    #attr{
        width: 20%;
    }
    #b-arr3 {
        padding: 0 1rem;
    }
    #b-arr3 img{
        width: 50%;
    }
}


@media only screen and (max-width:1100px) {
    #b-arr1 {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    #b-arr11{
        width: 100%;
        margin: 2rem 0.5rem;
        font-size: 40px;
    }
    #b-new {
        font-size: 20px;
        margin: 0.5rem;
        width: 100%;
    }
    #barr12-img {
        position: absolute;
        top: 0%;
        width: 90%;
        right: 0;
    }
    #b-arr12{
        padding: 1rem 0.5rem;
        justify-content: end;
    }
    #barr121{
        width: 100%;
        font-size: 21px;
    }
    .b-arr2 {
        flex-wrap: wrap;
        padding: 1rem 1rem;
    }
    .b-arr2 img{
        width: 90%;
        margin: auto;
    }
    .side{
        width: 100%;
    }
    .side-1{
        width: 100%;
        font-size: 40px;
    }
    .side-2{
        font-size: 15px;
    }
    #attr{
        width: 100%;
    }
    #b-arr3{
        padding: 1rem .5rem;
        flex-wrap: wrap;
    }
    #b-arr3 img{
        width: 95%;
        margin: auto;
    }
    #arr3-text{
        width: 100%;
    }
    #text-1{
        font-size: 25px;
        padding: 1rem;
    }
    #text-2{
        font-size: 25px;
        padding: 1rem;
    }
    #b-arr12 {
        width: 100%;
        margin-left: 0%;
    }
    #last-btn1 {
        display: block;
        margin: auto;
        margin-top: 50px;
    }
}



