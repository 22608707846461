#arr2 {
    /* background-image: url('../assets/img/home/FutureofAdvertising.png'); */
    /* height: 100vh; */
    background-position: center;
    position: relative;
    /* margin: 10rem auto; */

    overflow: hidden;
    background-size: cover;
    padding-top: 1rem;
    text-align: center;
}

.logo img {
    height: 80px;
}


#arr2-1 h3, #arr2-1 h2{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    line-height: 64px;
    /* or 141% */

    text-align: center;
    letter-spacing: -0.055em;

    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

#arr2-1 {
    padding-top: 6rem;
    text-align: center;
}

#arr2-3 {
    display: block;
    margin: auto;
}
#book-a-free{
    border: none;
    display: block;
    margin: auto;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: 'Inter';
    font-weight: 500;
    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    transition-duration: 0.5s;
    cursor: pointer;
    border: 1px solid #00888F;
}
#book-a-free:hover{
    transform: scale(1.1);
}

#arr2-2-1 h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.055em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    color: #0c6b7e;
    text-transform: uppercase;
}

#arr2-2-2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    /* or 161% */
    width: 56%;
    margin: auto;
    text-align: center;

    color: #141414;

    mix-blend-mode: normal;
}

#arr3 {
    overflow-x: hidden;
    margin: 10rem auto;
}

#arr3-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 64px;
    /* identical to box height, or 212% */
    margin: 1rem auto;
    margin-bottom: 4rem;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;

    background: linear-gradient(90deg, #00888F 4.32%, #770370 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

#arr3-2 {
    display: flex;
    /* flex-wrap: wrap; */
    margin: 2rem 0px;
}

#arr3-2-1 {
    animation: move1 60s linear infinite;
}

#arr3-2-2 {
    animation: move2 60s linear infinite;
}

@keyframes move1 {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes move2 {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    50.01% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(-100%);
    }

}

#arr4 {
    display: flex;
    flex-wrap: wrap;
    padding: 5rem;
    align-items: center;
    justify-content: space-evenly;
}

#arr4-1 {
    width: 39%;
}

#arr4-2 {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
}

#arr4-2 img {
    transition-duration: 0.5s;
}

#arr4-2 img:hover {
    transform: scale(1.3);
}

#arr4-1-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 64px;
    /* or 212% */
    margin: 1rem auto;
    letter-spacing: -0.03em;

    background: linear-gradient(90.71deg, #0098BB 1.97%, #340031 31.53%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

#arr4-1-2{
  width: 88%;
}

#arr4-1-2 h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 53px;
    line-height: 126.19%;
    /* or 76px */
    margin: 1rem auto;
    letter-spacing: -1px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    color: #000000;
}

#arr4-1-3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 158.69%;
    /* or 32px */
    padding-right: 3px;
    margin: 1rem auto;
    text-align: justify;

    color: #000000;
}

#arr5 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 8rem;
    /* margin-bottom: 25rem; */
    justify-content: start;
    gap: 10rem;
}

.head-small h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 36px;
    /* letter-spacing: 0em; */
    margin-block-start: 0em;
    margin-block-end: 15px;
    color: #0c6b7e;
}

.head-sub {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 153% */

    text-align: justify;
    letter-spacing: -0.03em;

    color: #000000;
}


#arr5-2 {
    width: 34%;
}

#arr5-1 {
    width: 46%;
    /* background: url(../../src/assets/img/home/side-bg.svg); */

}

#sidebg {
    position: absolute;
    left: -23%;
    bottom: -67%;
    /* height: 455px; */
    height: 119%;
}

#arr5-2-1 {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
}

#arr5-2-2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 153% */

    text-align: justify;
    letter-spacing: -0.03em;

    color: #000000;
}

#arr5-2-1-1 h2{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 58px;
    line-height: 80px;
    /* or 124% */
    margin-block-start: 0em;
    margin-block-end: 0em;
    letter-spacing: -0.03em;

    color: #000000;
}

#arr5-2-1-1 h3{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 60px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    letter-spacing: -0.03em;
    color: #000000;
    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.img-block {
    display: block;
    height: 80px;
    width: 90px;
    margin: 0 1rem;
    margin-left: 0px;
}

.div-flex {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: start;
    padding: 30px 0;
}

.div-flex-dark {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    padding: 30px 0;
}

.div-flex-dark .head-small h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    /* letter-spacing: 0em; */
    margin-block-start: 0em;
    margin-block-end: 0px;
    color: #ffffff;
}

.div-flex-dark span {
    color: #ffffff;
    font-size: 17px;
    letter-spacing: 0.5px;
    font-weight: 300;
}

.sub-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 153% */
    margin: 1rem auto;
    text-align: justify;
    letter-spacing: -0.03em;

    color: #000000;
}

.heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 88px;
    /* identical to box height, or 440% */

    letter-spacing: -0.05em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.subHeading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    /* or 168% */
    width: 88%;
    text-align: justify;
    letter-spacing: -0.05em;

    color: #FFFFFF;
}

#arr6-1-1-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 55px;
    line-height: 0px;
    margin: 5rem 0px;
    /* identical to box height, or 88% */

    letter-spacing: -0.05em;

    color: #FFFFFF;
}

#arr6-1 {
    /* background: linear-gradient(158.04deg, #00888F 0%, #340031 85.96%), linear-gradient(179.33deg, #232323 1.89%, #101010 99.42%); */
    background: linear-gradient(158.04deg, #246678 0%, #340031 85.96%), linear-gradient(179.33deg, #232323 1.89%, #101010 99.42%);
    width: 80%;
    padding: 7rem 7rem;
    padding-bottom: 10rem;
    position: relative;
    box-sizing: border-box;
}

#arr6 {
    margin: 10rem auto;
}

#arr6-1-1 {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
}

#arr6-1-1-2,
#arr6-1-1-3 {
    width: 45%;
    margin: 5rem 0px;
    margin-top: 2rem;
}

#arr6-img {
    top: -20rem;
    left: -5rem;
    position: absolute;
    transition-duration: 1.5s;
}

#arr6-img:hover {
    transform: scale(1.05);
}

#arr6-1-2 .subHeading {
    width: 100%;
}

#arr6-2 {

    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
}

#arr6-2-2-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 100px;
    line-height: 108px;
    /* identical to box height, or 108% */
    margin: 2rem auto;
    letter-spacing: -0.05em;

    color: #FFFFFF;
}

#arr6-2-2 {
    width: 53%;
}

#arr6-2-2-2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    /* or 152% */

    letter-spacing: -0.055em;
    margin: 2rem auto;
    color: #FFFFFF;
}

#arr6-2-2-3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 34px;
    /* identical to box height, or 223% */

    text-align: justify;
    letter-spacing: -0.05em;
    background: transparent;
    border: 2px solid white;
    border-radius: 10px;
    width: 177px;
    height: 48px;
    text-align: center;
    margin: 3rem auto;
    margin-bottom: 0px;
    color: #FFFFFF;
    transition-duration: 0.5s;
    cursor: pointer;
}

#arr6-2-2-3:hover {
    transform: scale(1.07);
}

.timehead {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 77px;
    /* or 154% */

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: end; */
    letter-spacing: -0.055em;
    /* text-align: right; */
    background: linear-gradient(101.17deg, #00888F 16.69%, #340031 71.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.timebody {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 166% */

    text-align: right;
    letter-spacing: -0.055em;

    color: #000000;
}

.relative {
    position: absolute;
}

#arr8-1 {
    display: flex;
    flex-wrap: wrap;
}

#timeline-left,
#timeline-right {
    width: min-content;
    position: relative;
}

.time {
    text-align: right;
}

#arr8 {
    padding: 5rem 11vw;
    display: flex;
    flex-wrap: wrap;

}

#t2016 {
    transform: translateY(35px) translateX(10px);
}

#t2017 {
    transform: translateY(157px) translateX(-8px);
}

#t2018 {
    transform: translateY(269px) translateX(5px);
}

#t2019 {
    transform: translateY(376px) translateX(-8px);
}

#t2020 {
    transform: translateY(602px) translateX(8px);
}

#t2021 {
    transform: translateY(701px) translateX(-7px);
}

#t2024 {
    transform: translateY(878px) translateX(8px);
}

#t2026 {
    transform: translateY(975px) translateX(-7px);
}

#timeline-right {
    width: 252px;
}

#arr8-2-1 {
    position: relative;
    padding: 0rem 6rem;
}

.img-absolute {
    position: absolute;
}

/* @media screen and (max-width: 1600px) {
    #arr8-2-1{
        padding-left: 3vw;
    }
  } */

#arr8-img1 {
    top: 65px;
    left: 189px;
    animation: grow 2s ease-in-out infinite;
}

#arr8-img2 {

    /* top: 115px;
    left: 155px; */
    top: 65px;
    left: 189px;
    animation: grow 2s ease-in-out infinite;
    animation-delay: 0.2s;

}

#arr8-img3 {
    /* top: 160px;
    left: 200px; */
    top: 65px;
    left: 189px;
    animation: grow 2s ease-in-out infinite;
    animation-delay: 0.5s;
}

#arr8-img4 {
    scale: 0.4;
    top: 35px;
    left: 189px;
}

@keyframes grow {
    0% {
        scale: 0;
        opacity: 0;
    }

    40% {
        opacity: 1;
    }


    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        scale: 1;
    }
}

#arr8-2 {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
    width: 40vw;
}

#arr8-2-1 {
    height: 600px;
}

#arr8-2-2 {
    width: 70%;
    margin-left: 14rem;
    margin-bottom: 14rem;
}

#arr8-2-2-1 h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 42px;
    /* or 140% */
    margin: 1px 0px;
    letter-spacing: -0.055em;
    text-transform: capitalize;

    color: #000000;
}
#arr8-2-2-1 h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 58px;
    margin: 1rem 0px;
    letter-spacing: -0.055em;
    text-transform: capitalize;
    color: #000000;
    background: linear-gradient(179deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#arr8-2-2-2 {
    background: transparent;
    border: 1px solid #000000;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* border: none; */
    width: 144px;
    height: 46px;
    margin: 3.5rem 0 0 0px;
    text-align: center;
    text-transform: uppercase;
    transition-duration: 0.5s;
    /* color: #FFFFFF; */
    cursor: pointer;
}

.headerBtn{
    margin-left: 10rem;
    margin-top: 1rem;
    display: flex;
    gap:3rem;
}

.headerBtn #arr8-2-2-2 {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    /* border: none; */
    width: 144px;
    height: 46px;
    margin: 1rem  0px;
    text-align: center;
    text-transform: capitalize;
    transition-duration: 0.5s;
    color: #FFFFFF;
    cursor: pointer;
}

.headerBtn #arr8-2-2-2:nth-child(1) {
    background: #ffffff;
    color: #000000;
    z-index: 1;
}

.headerBtn #arr8-2-2-2:nth-child(2) {
    width: 12rem;
}

#arr8-2-2-2:hover {
    transform: scale(1.05);
}

#arr8-2-2-3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    /* or 164% */
    margin: 1rem 0px;
    letter-spacing: -0.055em;

    color: #000000;
}

#arr8-0 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 64px;
    /* or 212% */
    /* width: 100vw; */
    width: 23%;
    margin: auto;
    flex-wrap: wrap;
    text-align: center;
    letter-spacing: -0.055em;
    text-transform: uppercase;

    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

#arr7-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 64px;
    /* or 212% */
    width: 15%;
    margin: auto;
    text-align: center;
    letter-spacing: -0.03em;

    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

#arr7-2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 64px;
    /* or 159% */

    text-align: center;
    letter-spacing: -0.055em;
    width: 21%;
    margin: auto;

    background: linear-gradient(90.23deg, #0098BB 18.77%, #340031 83.27%), #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}


#back0 {
    display: flex;
    /* background: linear-gradient(180deg, #00888F 0%, #2E012B 88.53%); */
    position: absolute;
    top: 300px;
    height: 50%;
    z-index: -1;
}


#back11 {
    animation: move11 15s linear infinite;
    opacity: 1;
    width: 100vw;
}

#back12 {
    animation: move12 15s linear infinite;
    opacity: 1;
    width: 100vw;
}

.homepage-m1{
    line-height: 32px;
}




@keyframes move11 {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes move12 {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    50.01% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(-100%);
    }

}


@media only screen and (max-width:1600px) {
    .header .headText h1{
        font-size: 120px;
        line-height: 140px;
        margin-top: 5rem;
    }
    .header{
        min-height: 750px;
    }
    #arr2-3{
        width: 70vw;
        margin-top:-3rem;
    }
    .img-block{
        width: 80px;
        height: 70px;
    }
    .div-flex-dark .img-block{
        width: 45px;
        height: 50px;
    }

    .div-flex-dark .img-space{
        padding-left: 5px;
    }
    #arr5-2-1-1{
        font-size: 60px;
        line-height: 80px;
    }
}


@media only screen and (max-width:1460px) {
    .header .headText h1{
        font-size: 100px;
        line-height: 130px;
        margin-top: 4rem;
    }
    .header{
        min-height: 750px;
    }
    #arr2-3{
        width: 70vw;
    }
    #arr4-2 img{
        width: 33%;
    }
    .img-block{
        width: 70px;
        height: 60px;
    }
    #arr5-2-1-1{
        font-size: 50px;
        line-height: 75px;
    }
    #heading-margin{
        margin-top: 80px;
    }
    #arr6-1 {
        width: 90%;
    }
    #arr6-2-1{
        width: 40%;
    }
    #arr6-2-1 img{
        width: 80%;
    }
    #arr7-1 {
        width: 20%;
    }
    #arr7-2 {
        width: 30%;
    }
    #arr8-0{
        width: 30%;
    }
    #arr8 {
        padding: 5rem 5vw;
    }
    #arr8-img4{
        scale: 0.2;
        left: 60px;
    }
    #arr8-img3,#arr8-img2,#arr8-img1{
        left: 60px;
    }

    @keyframes grow {
        0% {
            scale: 0;
            opacity: 0;
        }
    
        40% {
            opacity: 1;
        }
    
    
        80% {
            opacity: 1;
        }
    
        100% {
            opacity: 0;
            scale: 0.7;
        }
    }
    #arr8-1{
        scale: 0.9;
    }
    #arr8-2-2 {
        margin-left: 5rem;
        width: 100%;
    }
}


@media only screen and (max-width:450px) {

   
}


@media only screen and (max-width:1000px) {
    body,html{
        overflow-x: hidden;
    }
    .header .headText h1{
        width: 90%;
        font-size: 50px;
        margin-left: 2rem;
        margin-top: 6rem;
        line-height: 90px;
    }

    .header .subText {
        margin-left: 2rem;
        width: 250px;
    }

    .headerBtn {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .logo img {
        height: 60px;
    }

    .header {
        min-height: 802px;
    }

    #arr2-1 {
        font-size: 40px;
        line-height: 50px;
    }

    #back0 {
        top: 317px;
        height: 170px;
        z-index: -1;
    }

    #arr2-3 {
        width: 100%;
    }

    #arr2-2-1 {
        font-size: 25px;
        line-height: 40px;
        text-align: center;
        width: 95%;
        margin: auto;
    }
    #heading-margin{
        margin-top: 10px;
    }
    #arr2-2-2 {
        font-size: 15px;
        line-height: 29px;
        width: 90%;
        margin: 2rem auto;
        text-align: justify;
    }

    #arr3-1 {
        font-size: 30px;
        width: 80%;
        margin: auto;
        line-height: 50px;
    }

    #arr3 {
        margin: 3rem 0px;
    }
    #arr3-2 {
        display: flex;
        height: 55px;
        margin: 2rem 0px;
    }
    #arr4 {
        padding: 1rem;
    }

    #arr4-1 {
        width: 100%;
    }

    #arr4-1-1 {
        font-size: 30px;
        line-height: 50px;
        margin: 0px auto;
    }

    #arr4-1-2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        width:100%;
    }

    #arr4-1-3 {
        font-size: 15px;
    }

    #arr4-2 {
        width: 100%;
    }

    #arr4-2 img {
        width: 33%;
    }

    #arr5 {
        margin: 5rem 0px;
        padding: 1.2rem;
    }

    #arr5-1 {
        width: 100%;
    }

    .img-block {
        height: 50px;
        width: 50px;
    }

    .head-small {
        width: 80%;
        font-size: 20px;
        line-height: 25px;
    }

    .sub-text {
        font-size: 15px;
        line-height: 25px;
    }

    #arr5-2-1-1 {
        font-size: 25px;
        line-height: 30px;
        width: 80%;
        font-weight: 400;
        margin: 1rem 0px;
        width: auto;
    }

    #arr5-2 {
        width: 100%;
        margin: 3rem 0px;
    }

    #arr5-2-2 {
        font-size: 15px;
        line-height: 25px;
    }

    #arr6-img {
        width: 100vw;
        top: -12rem;
    }

    #arr6-1 {
        background: linear-gradient(158.04deg, #00888F 0%, #340031 85.96%), linear-gradient(179.33deg, #232323 1.89%, #101010 99.42%);
        width: 100%;
        padding: 10rem 0rem;
        padding-bottom: 10rem;
        position: relative;
        box-sizing: border-box;
    }

    #arr6-1-1-1 {
        font-size: 60px;
        line-height: 80px;
        margin-left: 10px;
    }
    .div-flex-dark {
    flex-wrap: wrap;
    }

    #arr6-2-1 {
        width: 50%;
        display: block;
        margin: auto;
    }

    #arr6-1-1-2,
    #arr6-1-1-3 {
        width: 95%;
        margin: 3rem auto;
    }
    #arr6-2-2-3{
        margin-left: 10px;
    }

    .subHeading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 34px;
        width: 88%;
        text-align: justify;
        letter-spacing: -0.05em;
        color: #FFFFFF;
    }

    .heading {
        font-size: 20px;
        line-height: 35px;
    }

    #arr6-1-2 {
        margin: 1rem;
    }

    #arr6-2-img {
        width: 60%;
    }

    #arr6-2 {
        margin-top: 5rem;
    }

    #sidebg {
        display: none;
    }

    #arr6-2-2 {
        width: 100%;
    }

    #arr6-2-2-1 {
        margin-left: 10px;
        font-size: 80px;
        margin-left: 12px;
    }

    #arr6-2-2-2 {
        font-size: 20px;
        margin: 2rem 0.5rem;
    }

    #arr6 {
        margin-bottom: 0px;
    }

    #arr7-1 {
        width: 100%;
    }

    #arr7-2 {
        width: 100%;
    }

    .card {
        padding: 1rem;
    }

    #arr8-0 {
        width: 100%;
    }

    .relative {
        width: 99%;
    }

    #timeline-left,
    #timeline-right {
        width: 48.25vw;
    }

    #arr8 {
        padding: 5rem 1vw;
        position: relative;
        right: -10px;
    }

    #t2024 {
        width: 99%;
    }

    #arr8-img1 {
        top: 65px;
        width: 100%;
        left: 0px;
    }
    #arr8-2 {
        display: flex;
        flex-wrap: wrap;
        /* align-items: center; */
        flex-direction: column;
        justify-content: space-between;
        width: 90vw;
    }
    #arr8-img2 {
        top: 65px;
        width: 100%;
        left: 0px;
    }

    #arr8-img3 {
        top: 65px;
        width: 100%;
        left: 0px;
    }

    #arr8-img4 {
        scale: 0.4;
        top: 35px;
        left: 0px;
        width: 100%;
    }
    #arr8-2-1 {
        height: 430px;
    }

    #arr8-2-2 {
        width: 100%;
        text-align: center;
        margin: 1rem 0.5rem;
    }

    #arr8-2-2-1 {
        font-size: 25px;
        line-height: 40px;
    }

    #arr8-2-2-3 {
        font-size: 15px;
        line-height: 25px;
    }

    .header nav ul li a {
        display: none;
    }

    #get-started {
        transition-duration: 0.5s;
        display: none;
    }
    #arr6-1-1 {
        
        padding: 0px 10px;
        
    }
    #arr2-1 h3, #arr2-1 h2 {
        font-size: 24px;
        line-height: 40px;
    }
    #arr2-2-1 h3 {
        font-size: 20px;
        line-height: 30px;
    }
    #arr4-1-2 h2 {
        font-size: 35px;
        line-height: 115.19%;
    }
    #arr5-2-1-1 h2 {
        font-size: 45px;
        line-height: 60px;
    }
}



