.heading-lower-contact {
   background: linear-gradient(90.71deg, #0098BB 1.97%, #6e02697b 31.53%), #000000;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-align: center;
   text-transform: uppercase;
   font-size: 2rem;
   width: 800px;
   display: block;
   margin: auto;
   font-weight: 500;
   margin-top: 50px;
   margin-bottom: 100px;

}

.lower-conten-box {
   overflow: hidden;
   display: flex;
   justify-content: space-evenly;
   background-image: url("../../assets/img/home/bg-lower-contact.png");
   background-position: right;
   background-size: contain;
   background-repeat: no-repeat;
   margin-bottom: 150px;
}

.lower-content-left {
   width: 40vw;
   max-width: 420px;
   font-weight: normal;
   font-size: 24.5px;

}

.lower-content-left img {
   width: 400px;
   margin-top: 20px;
}

#get-demo {
   position: relative;
}

.get-a-demo-border {
   padding: 3px;
   background: linear-gradient(to right, #00888F, #580053);
   border-radius: 10px;
}

.lower-conter-right {
   width: 40vw;
   max-width: 450px;
   /* background-image: url("../../assets/img/home/bg-lower-contact.png"); */
}

.get-a-demo-form {
   background-color: white;
   border-radius: 7px;
   padding: 20px;
   padding-right: 23px;

}

#get-1 {
   text-align: center;
   font-weight: bold;
   font-size: 1.5rem;
   color: black;
   margin-bottom: 40px;
}

.get-a-demo-form p {
   font-weight: bold;
   color: rgb(156, 156, 156);
}

.get-a-demo-form input,
textarea{
   width: 100%;
   height: 40px;
   background-color: rgb(246, 246, 246);
   border: none;
   border-radius: 15px;
}
.get-a-demo-form select{
   width: 100%;
   height: 50px;
   background-color: rgb(246, 246, 246);
   border: none;
   border-radius: 15px;
}

#input-2 {
   margin-bottom: 30px;
   height: 80px;
}

.get-a-demo-form button {
   background: linear-gradient(to right, #00888F, #580053);
   border: none;
   color: white;
   padding: 10px 22px;
   font-size: 22px;
   border-radius: 5px;
   display: block;
   margin: 20px auto;
   transition-duration: 0.5s;
}

.get-a-demo-form button:hover {
   transform: scale(1.05);
   cursor: pointer;
}

.lower-text-contact {
   font-size: 11px;
   font-weight: 400 !important;
   color: rgb(73, 73, 73);
   margin-top: 30px;
   text-align: center;
}

.lower-text-contact a {
   text-decoration: none;
   color: #580053;
}

@media only screen and (max-width:1100px) {
   .heading-lower-contact {
      font-size: 25px;
      width: 95%;
   }

   .lower-conten-box {
      flex-wrap: wrap;
   }

   .lower-content-left {
      width: 93vw;
      max-width: 90%;
      font-weight: normal;
      font-size: 15px;
  }
  #lowfootimg{
   width: 97%;
   margin-bottom: 2rem;
  }
  .lower-conter-right{
   width: 90%;
   max-width: 90%;
  }
}