.json-inner-content{
   padding-left: 4vw;

}
.data-container{
   padding-left: 2vw;
}
.identifiers{
   padding-left: 0.5vw;
}
pre {
   display: block;
   font-family: monospace;
   white-space: pre;
   margin: 1em 0px;
   word-wrap: break-word;
    white-space: pre-wrap;
}